
import { FullBleedImage } from './modules/fullBleedImagesIE';
import { nodeListToArray } from './helpers/nodeListToArray';
import { InitializeEmailSignupPage } from './modules/email-signup';

(() => {

	// Fix images in IE that must be full-bleed
	let fullBleedImages = nodeListToArray(document.querySelectorAll('[data-full-bleed-image] img'));
	fullBleedImages.forEach(imageEl => {
		new FullBleedImage(imageEl);
	})
    InitializeEmailSignupPage();
})();

